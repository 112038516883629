i  {
  text-align: center;
  width: 30px;
};



.productcard {
    width: 18rem;
};
.pushright {
    text-align: right;
};
